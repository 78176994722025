import { Alert as AlertUI, AlertIcon } from "@chakra-ui/react";

const Alert = ({ id, status, content }) => {
  return (
    <AlertUI id={id} status={status}>
      <AlertIcon />
      {content}
    </AlertUI>
  );
};

export default Alert;
