import styled from 'styled-components';
import useMedia from 'use-media';

import background from '../../images/bg-header.jpg';

const Background = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
  min-height: 80px;
  padding: 10px ${({ isSmall }) => (isSmall ? '0' : '200px')};

  h1 {
    z-index: 2;
  }
`;

const HeaderBanner = ({ children }) => {
  const isSmallTitle = useMedia({ maxWidth: '870px' });

  return <Background isSmall={isSmallTitle}>{children}</Background>;
};

export default HeaderBanner;
