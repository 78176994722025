import styled from 'styled-components';

interface GridProps {
  columns?: string;
  rows?: string;
  rowGap?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  position?: [];
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-rows: ${({ rows }) => rows};
  grid-template-columns: ${({ columns }) => columns};
  gap: ${({ gap = 0 }) => gap};
  grid-column: ${({ position: [from, to] = [null, null] }) =>
    from && to ? `${from} / ${to + 1}` : ''};
  row-gap: ${({ rowGap }) => rowGap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export default Grid;
