import styled from 'styled-components';
import useMedia from 'use-media';

import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';

import logo from '../../images/logo-bca-1.svg';

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isSmall }) => isSmall && 'column'};

  padding: ${({ isSmall }) => (isSmall ? '4px 8px' : '1rem 40px')};
  color: #fff;
  width: 100%;

  #information-link {
    display: ${({ isSmall }) => (isSmall ? 'grid' : 'flex')};
    gap: ${({ isSmall }) => (isSmall ? '0px' : '10px')};
  }

  ul {
    display: flex;
    gap: 10px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    background-color: white;
    border-radius: 50px;
    width: ${({ isSmall }) => (isSmall ? '20px' : '30px')};
    height: ${({ isSmall }) => (isSmall ? '20px' : '30px')};
    cursor: pointer;
  }

  li:hover {
    background-color: #d4d4d4;
  }

  a {
    color: #215193;
  }
`;

const FooterIcon = styled.a`
  color: #1c1c1c !important;
  font-size: 1.563rem !important;
  &:hover {
    color: #215193 !important;
  }
`;

const menu = [
  {
    id: 'facebook',
    href: 'https://www.facebook.com/BCAexpertisefrance/',
    icon: <FaFacebookSquare />
  },
  {
    id: 'twitter',
    href: 'https://twitter.com/BCA_Expertise',
    icon: <FaTwitter />
  },
  {
    id: 'linkedin',
    href: 'https://www.linkedin.com/company/bca-expertise/',
    icon: <FaLinkedin />
  },
  {
    id: 'youtube',
    href: 'https://www.youtube.com/channel/UCaUuyKsZ9GwnNOZdkHW1f3w',
    icon: <FaYoutube />
  },
  {
    id: 'instagram',
    href: 'https://www.instagram.com/bca_expertise/',
    icon: <FaInstagram />
  }
];

const contact = [
  {
    id: 'mentions',
    href: 'https://www.bca.fr/mentions-legales/',
    name: 'Mentions légales'
  },
  {
    id: 'questions-reponses',
    href: 'https://www.bca.fr/faq/',
    name: 'FAQ'
  },
  {
    id: 'contactez-nous',
    href: 'https://www.bca.fr/map-agences/',
    name: 'Contact'
  }
];

const Footer = () => {
  const isSmall = useMedia({ maxWidth: '820px' });
  const isExtraSmall = useMedia({ maxWidth: '425px' });

  return (
    <StyledFooter isSmall={isSmall} isExtraSmall={isExtraSmall}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          flexDirection: isSmall && 'column'
        }}
      >
        <img alt="logo-bca" src={logo} />
        <div
          style={{
            display: 'flex',
            gap: isSmall && '20px'
          }}
        >
          {menu.map(({ id, href, icon }) => (
            <li key={`Footer-Menu-${id}`}>
              <FooterIcon href={href} target="_blank" rel="noreferrer">
                {icon}
              </FooterIcon>
            </li>
          ))}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isSmall ? 'flex-start' : 'end'
        }}
      >
        <div>
          <ul
            id="information-link"
            style={{ display: isSmall && 'flex', marginTop: isSmall && '15px' }}
          >
            {contact.map(({ id, href, name }) => (
              <a
                style={{
                  color: '#001c82',
                  fontSize: '0.8rem',
                  letterSpacing: '2px',
                  margin: '0 15px'
                }}
                key={`Contact-Menu-${id}`}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>
            ))}
          </ul>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
