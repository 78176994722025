import { memo } from "react";
import type { KcContext } from "./kcContext";
import { defaultKcProps } from "keycloakify";
// import { Login } from 'keycloakify/lib/components/Login';
import { Register } from "./Register";
import { Login } from "./Login";
import { Info } from "keycloakify/lib/components/Info";
import { Error } from "keycloakify/lib/components/Error";
// import { LoginResetPassword } from "keycloakify/lib/components/LoginResetPassword";
import { LoginResetPassword } from "./LoginResetPassword";
import { LoginVerifyEmail } from "keycloakify/lib/components/LoginVerifyEmail";
import { Terms } from "./Terms";
import { LoginOtp } from "keycloakify/lib/components/LoginOtp";
import { LoginUpdateProfile } from "keycloakify/lib/components/LoginUpdateProfile";
import { LoginIdpLinkConfirm } from "keycloakify/lib/components/LoginIdpLinkConfirm";
import { RegisterUserProfile } from "keycloakify/lib/components/RegisterUserProfile";
import { LoginUpdatePassword } from "keycloakify/lib/components/LoginUpdatePassword";
import { MyExtraPage1 } from "./MyExtraPage1";
import { MyExtraPage2 } from "./MyExtraPage2";
import "./kcMessagesExtension";

const urls = {
  localhost: "http://localhost:3002/extranet-front",
  "auth-int.bca.fr": "https://apps-int.bca.fr/extranet-front",
  "auth-rec.bca.fr": "https://apps-rec.bca.fr/extranet-front",
  "auth-preprod.bca.fr": "https://apps-preprod.bca.fr/extranet-front",
  "auth.bca.fr": "https://apps.bca.fr/extranet-front",
};

export const KcApp = memo(({ kcContext }: { kcContext: KcContext }) => {
  const host = window.location.host.split(":")[0];
  const props = { ...defaultKcProps, front: urls[host] ?? urls["auth.bca.fr"] };

  switch (kcContext.pageId) {
    case "login.ftl":
      return <Login {...{ kcContext, ...props }} />;
    case "register.ftl":
      return <Register {...{ kcContext, ...props }} />;
    case "info.ftl":
      return <Info {...{ kcContext, ...props }} />;
    case "error.ftl":
      return <Error {...{ kcContext, ...props }} />;
    case "login-reset-password.ftl":
      return <LoginResetPassword {...{ kcContext, ...props }} />;
    case "login-verify-email.ftl":
      return <LoginVerifyEmail {...{ kcContext, ...props }} />;
    case "terms.ftl":
      return <Terms {...{ kcContext, ...props }} />;
    case "login-otp.ftl":
      return <LoginOtp {...{ kcContext, ...props }} />;
    case "login-update-profile.ftl":
      return <LoginUpdateProfile {...{ kcContext, ...props }} />;
    case "login-idp-link-confirm.ftl":
      return <LoginIdpLinkConfirm {...{ kcContext, ...props }} />;
    case "my-extra-page-1.ftl":
      return <MyExtraPage1 {...{ kcContext, ...props }} />;
    case "my-extra-page-2.ftl":
      return <MyExtraPage2 {...{ kcContext, ...props }} />;
    case "register-user-profile.ftl":
      return <RegisterUserProfile {...{ kcContext, ...props }} />;
    case "login-update-password.ftl":
      return <LoginUpdatePassword {...{ kcContext, ...props }} />;
  }
});
