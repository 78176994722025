import styled from 'styled-components';

import logo from '../../images/logo-bca-1.svg';
import logo_shadow from '../../images/round-circle.svg';

const HeaderContainer = styled.header`
  height: 90px;
  z-index: 100;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  background: white;
`;

const LogoBca = styled.img`
  position: relative;
  z-index: 1;
  background: #fff;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <a
        href="https://www.bca.fr/"
        target="_blank"
        rel="noreferrer"
        style={{ position: 'relative' }}
      >
        <LogoBca alt="logo-bca" src={logo} />

        <div
          style={{
            position: 'absolute',
            inset: '0',
            transform: 'scale(1.2) translate(0px, 44px)',
            background: `url(${logo_shadow}) center center / contain no-repeat`
          }}
        />
      </a>
    </HeaderContainer>
  );
};

export default Header;
