const colors = {
  yellow: {
    primary: "#fec600",
    secondary: "#fce9b4",
  },
  black: {
    primary: "#393939",
  },
  blue: {
    primary: "#0b519f",
    secondary: "#e9edfc",
  },
  white: {
    primary: "#fff",
  },
  brand: {
    yellow: "yellow.primary",
    black: "black.primary",
  },
};

export default colors;
