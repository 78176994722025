const Input = {
  parts: ["addon", "field"],
  defaultProps: {
    colorScheme: "yellow",
  },
  baseStyle: {
    border: "0px",
  },
  sizes: {
    md: {
      addon: {
        borderRadius: "0",
        fontSize: "md",
        border: "0px",
        borderBottom: "1px solid #393939",
      },
      field: {
        borderRadius: "0",
        fontSize: "md",
        border: "0px",
        borderBottom: "1px solid #393939",
      },
    },
  },
};

export default Input;
