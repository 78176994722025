import { forwardRef } from 'react';
import { Button as ButtonUi } from '@chakra-ui/react';

const Button = forwardRef(
  (
    props: {
      tabIndex?: number,
      id?: string,
      type?: string,
      style?: object,
      disabled?: boolean,
      rightIcon?: any,
      children: any
    },
    ref
  ) => {
    return <ButtonUi ref={ref} {...props} />;
  }
);

export default Button;
