import styled from "styled-components";
import { Box } from "@chakra-ui/react";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/accordion";

const ListHelp = styled(AccordionPanel)`
  a {
    border-bottom: 1px solid #ccc;
    color: #004fb6;
    font-weight: 700;
    padding-bottom: 1px;
    text-decoration: none;
    margin-left: 5px;
  }

  li {
    &:before {
      content: "• ";
      color: black;
      font-size: 20px;
    }
  }
`;

const StyledAccordionIcon = styled(AccordionIcon)`
  height: 1.5em !important;
  left: -1.75em !important;
  margin-right: 0.5em !important;
  width: 0.875em !important;
  font-size: 30px !important;
  color: #004fb6 !important;
`;

const StyledBlueLink = styled.a`
  border-bottom: 1px solid #004fb6;
  color: #004fb6;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 1px;
  &:hover {
    text-decoration: none !important;
  }
`;

const HelpContent = ({ title, children }) => {
  return (
    <>
      <Accordion defaultIndex={0} allowToggle borderColor="transparent">
        <AccordionItem>
          <h2>
            <AccordionButton paddingLeft="0px">
              <StyledAccordionIcon />
              <Box flex="1" textAlign="left">
                <StyledBlueLink>{title}</StyledBlueLink>
              </Box>
            </AccordionButton>
          </h2>

          <ListHelp pb={4}>{children}</ListHelp>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default HelpContent;
