import { useState, memo, useRef, useEffect } from 'react';
import type { KcProps, KcContextBase } from 'keycloakify';
import { useConstCallback } from 'powerhooks/useConstCallback';
import styled from 'styled-components';
import useMedia from 'use-media';
import { Box, ChakraProvider } from '@chakra-ui/react';

import theme from '../theme';

import {
  Input,
  Button,
  HelpContent,
  HeaderBanner,
  Header,
  Footer,
  Alert,
  Grid,
  Spinner
} from '../components/layout';

const StyledBlock = styled.div`
  padding-bottom: 1.25em;
  margin: 2em 0;
  clear: left;
`;

const StyledLink = styled.a`
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: #004fb6;
  text-align: right;
  font-weight: bold;
`;

const DefaultTitle = styled.h1`
  color: white;
  line-height: 2.5rem;
  font-size: 2rem;
  font-weight: bold;
`;

const SmallTitle = styled.h1`
  padding: 10px;
  line-height: 18px;
  font-size: 18px;
  font-weight: bold;
  background: #ffffffcf;
  color: #1e5193;
  text-align: center;
  width: 80%;
  box-shadow:
    0.5em 0 0 #ffffffcf,
    -0.5em 0 0 #ffffffcf;
`;

const LeftBlock = styled.div`
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RightBlock = styled.div`
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #004fb6;
`;

type Disclamer = {
  id: string;
  message: string;
  style: Record<string, string>;
};

export const Login = memo(
  ({
    kcContext,
    ...props
  }: { kcContext: KcContextBase.Login; front: String } & KcProps) => {
    const usernameRef = useRef<HTMLInputElement>();
    const submitRef = useRef<HTMLInputElement>();

    const { realm, url, usernameEditDisabled, login, auth, message } =
      kcContext;

    const [disclamers, setDisclamers] = useState<
      Record<Disclamer['id'], Disclamer>
    >({});

    const [accountActivated, setAccountActivated] = useState(false);

    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
    const [help, setHelp] = useState('username');
    const isSmall = useMedia({ maxWidth: '992px' });
    const isSmallTitle = useMedia({ maxWidth: '870px' });

    const [ready, setReady] = useState(false);

    const [username, setUsername] = useState(login.username ?? '');
    const [password, setPassword] = useState('');

    const [touchUsername, setTouchUsername] = useState(false);
    const [touchPassword, setTouchPassword] = useState(false);

    const onSubmit = useConstCallback(
      () => (setIsLoginButtonDisabled(true), true)
    );

    useEffect(() => {
      if (usernameRef?.current && usernameRef?.current?.focus)
        usernameRef.current.focus();
    }, []);

    useEffect(() => {
      const redirect_uri = new URL(document.location.href).searchParams.get(
        'redirect_uri'
      );

      if (redirect_uri === null) return;

      const account = new URL(redirect_uri).searchParams.get('account');

      if (account === 'activated') {
        setAccountActivated(true);
      }
    }, []);

    useEffect(() => {
      if (!submitRef.current) return;

      const client_id = new URL(document.location.href).searchParams.get(
        'client_id'
      );

      const prefix = '_customsso';
      if (!client_id?.includes(prefix)) {
        setReady(true);
        return;
      }

      const [client] = client_id.split(prefix);

      setTimeout(() => {
        setUsername(client);
        setPassword(client + '@sso.com');

        submitRef.current.click();
      }, 1000);
    }, []);

    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      (async () => {
        try {
          const response = await fetch(`${props.front}/api/status`, {
            signal
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const json: {
            status: number;
            disclamers: Record<Disclamer['id'], Disclamer>;
          } = await response.json();

          setDisclamers(json?.disclamers);
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error('Fetch error:', error);
          }
        }
      })();

      return () => abortController.abort();
    }, []);

    return (
      <ChakraProvider theme={theme}>
        <div className={ready ? '' : 'loading-app-mf'}>
          <div
            className="page-background"
            style={{
              minHeight: 'calc(100vh - 63px)'
            }}
          >
            <Header />

            <Box>
              <HeaderBanner>
                {
                  {
                    false: (
                      <DefaultTitle>
                        Bienvenue sur votre nouvel espace BCA Expertise
                      </DefaultTitle>
                    ),
                    true: (
                      <SmallTitle>
                        Bienvenue sur votre nouvel espace BCA Expertise
                      </SmallTitle>
                    )
                  }[isSmallTitle.toString()]
                }
              </HeaderBanner>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid
                  columns={isSmall ? '1fr' : '3fr 4fr'}
                  gap="40px"
                  style={{
                    width: isSmall ? '100%' : '1000px',
                    margin: 'auto',
                    padding: '20px'
                  }}
                >
                  <LeftBlock>
                    <form
                      id="kc-form-login"
                      onSubmit={onSubmit}
                      action={url.loginAction}
                      method="post"
                    >
                      <div
                        id="form-block"
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        {disclamers?.keycloak_theme_general?.message && (
                          <div
                            style={
                              disclamers?.keycloak_theme_general?.style ?? {}
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                disclamers?.keycloak_theme_general?.message ??
                                ''
                            }}
                          />
                        )}

                        {accountActivated && (
                          <div style={{ margin: '10px 0 30px 0' }}>
                            <Alert
                              id="accountActivated"
                              status="info"
                              content="Compte activé - Vous pouvez maintenant vous identifier pour accéder à votre espace"
                            />
                          </div>
                        )}

                        <pre style={{ display: 'none' }}>
                          {JSON.stringify(message, null, 2)}
                        </pre>

                        {message?.summary && (
                          <>
                            <Alert
                              id="error-message"
                              status="error"
                              content={
                                {
                                  'Invalid username or password.':
                                    'Identifiant ou mot de passe incorrect'
                                }[message.summary] ??
                                'Identifiant ou mot de passe incorrect'
                              }
                            />

                            <br />
                          </>
                        )}

                        <Input
                          ref={usernameRef}
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                          onBlur={() => setTouchUsername(true)}
                          errorText={
                            touchUsername &&
                            username === '' &&
                            'Identifiant obligatoire'
                          }
                          tabIndex={1}
                          id="username"
                          name="username"
                          placeholder="Identifiant *"
                          onFocus={() => setHelp('username')}
                          {...(usernameEditDisabled
                            ? { disabled: true }
                            : {
                                autoFocus: true,
                                autoComplete: 'off'
                              })}
                        />

                        <br />

                        <Input
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          onBlur={() => setTouchPassword(true)}
                          errorText={
                            touchPassword &&
                            password === '' &&
                            'Mot de passe obligatoire'
                          }
                          tabIndex={2}
                          style={{ marginBottom: '0px' }}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Mot de passe *"
                          onFocus={() => setHelp('password')}
                          autoComplete="off"
                        />

                        {realm.resetPasswordAllowed && (
                          <StyledLink
                            id="forgotPwd-link"
                            href={`${props.front}/auth/password/forgot`}
                          >
                            Mot de passe oublié
                          </StyledLink>
                        )}
                      </div>

                      <StyledBlock>
                        <input
                          type="hidden"
                          id="id-hidden-input"
                          name="credentialId"
                          {...(auth?.selectedCredential !== undefined
                            ? {
                                value: auth.selectedCredential
                              }
                            : {})}
                        />

                        <Button
                          ref={submitRef}
                          tabIndex={4}
                          id="kc-login"
                          type="submit"
                          style={{ width: '100%' }}
                          disabled={isLoginButtonDisabled}
                          rightIcon={isLoginButtonDisabled && <Spinner />}
                        >
                          Se connecter
                        </Button>
                      </StyledBlock>
                    </form>
                  </LeftBlock>

                  <RightBlock
                    style={{
                      width: isSmall && '100%',
                      margin: isSmall && '1.875em 0 0',
                      padding: '10px'
                    }}
                  >
                    <Title>Besoin d'aide</Title>

                    {help === 'username' ? (
                      <>
                        <HelpContent title="Où trouver mon identifiant ?">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                "Si vous disposez déjà d'un espace personnel et que vous êtes :<ul><li>Un assuré : vous devez utiliser votre adresse mail</li><li>Un réparateur : vous devez utiliser votre adresse mail</li><li>Un client de BCA Expertise : vous devez utiliser le code d'accès fourni par votre gestionnaire de compte</li></ul>"
                            }}
                          />
                        </HelpContent>

                        <HelpContent title="Première connexion en tant qu'assuré ?">
                          <ul>
                            <li>
                              Vous vous connectiez sur notre ancien site,
                              utilisez les mêmes identifiants qu'avant
                            </li>

                            <li>
                              Vous venez pour la première fois
                              <a href={`${props.front}/auth/generateCode`}>
                                inscrivez-vous ici
                              </a>{' '}
                              simplement avec votre adresse mail.
                            </li>
                          </ul>
                        </HelpContent>
                      </>
                    ) : (
                      <>
                        <HelpContent title="Vous avez oublié votre mot de passe ?">
                          Vous pouvez renouveler votre mot de passe
                          <a href={`${props.front}/auth/password/forgot`}>
                            en cliquant ici
                          </a>
                        </HelpContent>

                        <HelpContent title="Comment modifier votre mot de passe ?">
                          Une fois connecté, vous pourrez le modifier depuis
                          "Modification mot de passe"
                        </HelpContent>
                      </>
                    )}
                    <HelpContent title="Politique de confidentialité">
                      Pour accéder à notre politique de confidentialité{' '}
                      <a
                        target="_blank"
                        href={`${props.front}/auth/privacy/policy`}
                      >
                        cliquez ici
                      </a>
                    </HelpContent>
                  </RightBlock>
                </Grid>
              </div>
            </Box>
          </div>

          <Footer />
        </div>
      </ChakraProvider>
    );
  }
);
