import { memo } from "react";
import type { KcProps, KcContextBase } from "keycloakify";
import styled from "styled-components";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { ImArrowLeft } from "react-icons/im";
import useMedia from "use-media";

import theme from "../theme";

import {
  Input,
  Button,
  HelpContent,
  HeaderBanner,
  Header,
  Footer,
} from "../components/layout";

const StyledBody = styled.div`
  width: 357px;
  height: 381px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 1);
  border-radius: 5px;
`;

const StyledGroup = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.h1`
  color: white;
  line-height: 2.5rem;
  font-size: 2.375rem;
  font-weight: bold;
`;

const StyledBlock = styled.div`
  text-align: center;
  padding-bottom: 1.25em;
  clear: left;
`;

const StyledRightBlock = styled.div`
  width: 501px;
  height: 381px;
  background: inherit;
  background-color: #f2f2f2;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-left: 40px;
`;

const StyledSecondTitle = styled.h2`
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #004fb6;
`;

const StyledLinkUrl = styled.div`
  display: flex;
  margin: 20px;
`;

export const LoginResetPassword = memo(
  ({
    kcContext,
    ...props
  }: { kcContext: KcContextBase.LoginResetPassword } & KcProps) => {
    const { url, auth } = kcContext;
    const isSmall = useMedia({ maxWidth: "992px" });

    return (
      <ChakraProvider theme={theme}>
        <div>
          <div
            className="page-background"
            style={{
              minHeight: "calc(100vh - 63px)",
            }}
          >
            <Header />

            <Box>
              <HeaderBanner>
                <StyledTitle>Réinitialiser votre mot de passe</StyledTitle>
              </HeaderBanner>

              <StyledLinkUrl>
                <a
                  id="link-home"
                  style={{
                    color: "#004fb6",
                    display: "flex",
                    alignItems: "center",
                  }}
                  href={url.loginUrl}
                >
                  <ImArrowLeft style={{ marginRight: "10px" }} />
                  Retour à la page de connexion
                </a>
              </StyledLinkUrl>

              <div
                style={{
                  padding: "0 1.875em",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <StyledGroup
                  style={{
                    display: isSmall && "block",
                    margin: "70px",
                  }}
                >
                  <StyledBody style={{ width: isSmall ? "100%" : undefined }}>
                    <form
                      id="kc-reset-password-form"
                      action={url.loginAction}
                      method="post"
                      style={{ padding: "10px" }}
                    >
                      <div
                        id="form-block"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Input
                          tabIndex={1}
                          id="username"
                          name="username"
                          defaultValue={
                            auth !== undefined && auth.showUsername
                              ? auth.attemptedUsername
                              : undefined
                          }
                          placeholder="Adresse e-mail"
                          autoFocus
                        />
                      </div>

                      <StyledBlock>
                        <Button id="submit-email" type="submit">
                          Envoyer un e-mail
                        </Button>
                      </StyledBlock>
                    </form>
                  </StyledBody>

                  <StyledRightBlock
                    style={{
                      width: isSmall && "100%",
                      margin: isSmall && "1.875em 0 0",
                      padding: "10px",
                    }}
                  >
                    <StyledSecondTitle>Besoin d'aide</StyledSecondTitle>

                    <HelpContent title="Comment changer votre mot de passe ?">
                      Saisissez votre adresse e-mail et vous recevrez en
                      quelques minutes un lien qui vous permettra de choisir un
                      nouveau mot de passe.
                    </HelpContent>
                  </StyledRightBlock>
                </StyledGroup>
              </div>
            </Box>
          </div>

          <Footer />
        </div>
      </ChakraProvider>
    );
  }
);
