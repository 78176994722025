import Button from "./Button";
import Tabs from "./Tabs";
import Input from "./Input";

const components = {
  Button,
  Tabs,
  Input,
};

export default components;
