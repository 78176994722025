import { kcMessages } from "keycloakify/lib/i18n/useKcMessage";

Object.assign(kcMessages["fr"], {
  /* spell-checker: disable */
  foo: "foo en Francais",
  /* spell-checker: enable */
});

Object.assign(kcMessages["en"], {
  foo: "foo in English",
});
