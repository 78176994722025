import { extendTheme } from "@chakra-ui/react";

import breakpoints from "theme/breakpoints";
import colors from "theme/colors";
import components from "theme/components";

const theme = extendTheme({
  breakpoints,
  colors,
  components,
  shadows: { outline: "transparent" },
});

export default theme;
