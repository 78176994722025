import { useState, forwardRef, Fragment } from 'react';
import styled from 'styled-components';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { CloseIcon } from '@chakra-ui/icons';
import ReactTooltip from 'react-tooltip';

import { Alert } from 'components/layout';

const StyledCircle = styled(BsInfoCircleFill)`
  transform: translateY(-80%) scale(0.9);
`;

const StyledUi = styled.input`
  border: 0px;
  border-bottom: 1px solid #888;
  padding-left: 0px;
  text-indent: 0px;
  width: 100%;

  margin-bottom: ${({ variant, errorText }) =>
    variant === 'no-margin' || errorText ? '0px' : '20px'};
  color: #888;
  line-height: 30px;
  min-height: 2.5rem;
  padding: 0 0.5rem;

  &::-webkit-search-decoration:hover,
  &::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }
`;

const StyledInputUi = forwardRef(({ placeholder, ...props }, ref) => {
  return (
    <div className={props.className}>
      <div className="material-textfield">
        <StyledUi ref={ref} {...props} placeholder=" " />
        <label>{placeholder}</label>
      </div>
    </div>
  );
});

const StyledInput = styled(StyledInputUi)`
  width: 100%;
  color: black;

  .material-textfield {
    position: relative;
  }

  label {
    position: absolute;
    font-size: 14px;
    left: 0;
    top: 20px;
    transform: translateY(-50%);
    background-color: white;
    color: rgb(153, 153, 153) !important;
    padding: 0 0.3rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }

  input {
    transition: 0.1s ease-out;
  }

  input:focus {
    border-color: #6200ee;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label,
  input:-webkit-autofill + label {
    color: #6200ee;
    top: -13px;
    transform: translateY(-50%) scale(0.9);
  }
`;

const PasswordInput = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  return (
    <div style={{ marginTop: '20px' }}>
      <InputGroup size="md">
        <StyledInput ref={ref} {...props} type={show ? 'text' : 'password'} />

        <InputRightElement>
          <IconButton
            variant="link"
            size="lg"
            onClick={() => setShow(show => !show)}
            icon={show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          />
        </InputRightElement>
      </InputGroup>

      {props.errorText && (
        <Alert
          id={props.errorText ? 'error-message-' + props.id : ''}
          status="error"
          content={props.errorText}
        />
      )}
    </div>
  );
});

const InformationInput = forwardRef((props, ref) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <InputGroup size="md">
        <StyledInput ref={ref} {...props} />

        <InputRightElement style={{ paddingTop: '24px' }}>
          <StyledCircle data-tip data-for="registerTip" color="gray.300" />

          <ReactTooltip id="registerTip" place="top" effect="solid">
            {props.information.split('\n').map(value => (
              <Fragment key={value}>
                {value}
                <br />
              </Fragment>
            ))}
          </ReactTooltip>
        </InputRightElement>
      </InputGroup>

      {props.errorText && (
        <Alert
          id={props.errorText ? 'error-message-' + props.id : ''}
          status="error"
          content={props.errorText}
        />
      )}
    </div>
  );
});

const SearchInput = forwardRef((props, ref, onClick) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <InputGroup size="md">
        <StyledInput ref={ref} {...props} type="find" />

        <InputRightElement>
          <IconButton
            style={{ marginBottom: '20px' }}
            variant="link"
            onClick={onClick}
            icon={<CloseIcon />}
          />
        </InputRightElement>
      </InputGroup>
    </div>
  );
});

const Input = forwardRef(
  (
    props: {
      tabIndex?: number,
      id?: string,
      name?: string,
      type?: string,
      defaultValue?: string,
      placeholder?: string,
      errorText?: string,
      onFocus?: () => void,
      autoComplete?: string,
      style?: {
        marginBottom: string
      },
      disabled?: boolean,
      autoFocus?: boolean,
      onChange?: (e: any) => void,
      onBlur?: (e: any) => void,
      value?: string
    },
    ref
  ) => {
    if (props.type === 'password')
      return <PasswordInput ref={ref} {...props} />;
    if (props.type === 'find') return <SearchInput ref={ref} {...props} />;
    if (!!props.information) return <InformationInput ref={ref} {...props} />;

    return (
      <div style={{ marginTop: '20px' }}>
        <StyledInput ref={ref} {...props} />

        {props.errorText && (
          <Alert
            id={props.errorText ? 'error-message-' + props.id : ''}
            status="error"
            content={props.errorText}
          />
        )}
      </div>
    );
  }
);

export default Input;
